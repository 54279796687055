import { axiosInstance, handleResponse, getOptions } from '../api'
import { needdleConfig } from '../api/config'

export const DeliveryServices = {
    getCurrentDelivery: async (workspace_id: string) => {
        const options = await getOptions()
        return handleResponse(
            axiosInstance.get(`/deliveries/${workspace_id}/current`, options)
        )
    },
}
