import React from 'react'
import './GettingResultLayout.scss'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { PATH } from '../../constants/path'
import { ButtonType, NeedleButton } from '../../components/NeedleButton'
import SubscriptionCard from '../../components/Sidebar/SubscriptionCard'
import { useGetCurrentDeliveries } from '../../hooks/useDeliveries'

interface HeaderPartNavigationProps {
    credit_balance: number
    isMobile?: boolean
    workspaceId: string
}

export default function HeaderPartNavigation({
    credit_balance,
    isMobile = false,
    workspaceId
}: HeaderPartNavigationProps) {
    const navigate = useNavigate()

    const {
        data: currentDelivery,
        isLoading: isCurrentDeliveryLoading,
        refetch: refetchCurrentDelivery,
    } = useGetCurrentDeliveries(workspaceId)
    
    return (
        <div className="">
            {!isMobile && (
                <Box className="mt-5 mb-[66px] flex justify-center">
                    <img
                        src="/assets/svgs/needle-logo-2.svg"
                        alt="Needle logo"
                    />
                </Box>
            )}
            {/* {!isCurrentDeliveryLoading && currentDelivery?.asset?.length > 0 &&
                <SubscriptionCard
                    quotas={currentDelivery?.asset ?? []}
                    resetDate={currentDelivery?.next_cycle?.date ?? null}
                    isExtended={currentDelivery?.next_cycle?.is_extended ?? false}
                />
            } */}
            <Box className="flex flex-row font-sans font-semibold leading-130 text-base text-dark-blue justify-center items-center">
                <span className="pr-4">
                    {credit_balance} Credit{credit_balance !== 1 ? 's' : ''}
                </span>
                <div>
                    <NeedleButton
                        onClickFunction={() => {
                            navigate(PATH.creditPage)
                        }}
                        buttonType={ButtonType.White}
                        border="py-10px px-3"
                        buttonCharacter={'+ Add'}
                    />
                </div>
            </Box>
        </div>
    )
}
