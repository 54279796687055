import { IdeaDetailServices } from '../services/IdeaDetail/ideadetail.services'
import globalErrorDialogStore from '../storeZustand/globalErrorDialogStore'
import { Idea, IdeaStatus } from '../utils/needleEnum'
import useSWR, { mutate } from 'swr'
import { checkIfCurrentUserIsAdmin } from '../utils/utils'

const useIdeaDetail = (ideaId: string | undefined) => {
    if (ideaId) {
        const { data, mutate, isLoading, ...rest } = useSWR(
            `/get-idea-detail/${ideaId}`,
            async (id) => {
                const ideaDetails =
                    await IdeaDetailServices.getIdeaDetail(ideaId)
                return ideaDetails as Idea
            }
        )
        const reload = () => {
            mutate()
        }
        return { data, mutate, reload, isLoading, ...rest }
    } else {
        return {
            data: undefined,
            isLoading: false,
            error: undefined,
            reload: () => {},
        }
    }
}

export const changeIdeaStatus = async (
    id: string | undefined,
    status: IdeaStatus,
    reload: () => void,
    conceptId?: string,
    assetId?: string,
    
) => {
    try {
        if (id) {
            const userTacticStatus = await IdeaDetailServices.updateIdeaStatus(
                id,
                {
                    status: status,
                    concept_id: conceptId,
                    assetId: assetId,
                }
            )
            reload()
            mutate(`/get-growth-plan`)
            return userTacticStatus ?? undefined
        }
    } catch (e) {
        if (checkIfCurrentUserIsAdmin()) return
        if (status !== IdeaStatus.needle_is_working) {
            const { setIsOpenErrorDialog } = globalErrorDialogStore.getState()
            setIsOpenErrorDialog(true)
        }
        return undefined
    }
}
export const reportConceptsAssets = async (
    id: string | undefined,
    data: any
) => {
    try {
        if (id) {
            const userTacticStatus =
                await IdeaDetailServices.reportSomethingWrong(id, data)
            return userTacticStatus ?? undefined
        }
    } catch (e) {
        const { setIsOpenErrorDialog } = globalErrorDialogStore.getState()
        setIsOpenErrorDialog(true)
        return undefined
    }
}

export const changeConceptsData = async (
    ideaId: string,
    conceptID: string,
    data: any,
    reload: () => void
) => {
    try {
        if (ideaId && conceptID) {
            const userTacticStatus = await IdeaDetailServices.updateConcepts(
                ideaId,
                conceptID,
                data
            )
            reload()
            return userTacticStatus ?? undefined
        }
    } catch (e) {
        const { setIsOpenErrorDialog } = globalErrorDialogStore.getState()
        setIsOpenErrorDialog(true)
        return undefined
    }
}

export const createAnIdea = async (data: any, reload?: () => void) => {
    try {
        const userTacticStatus = await IdeaDetailServices.addAnIdea(data)
        if (reload) {
            reload()
        }
        return userTacticStatus ?? undefined
    } catch (e) {
        const { setIsOpenErrorDialog } = globalErrorDialogStore.getState()
        setIsOpenErrorDialog(true)
        return undefined
    }
}
export default useIdeaDetail
