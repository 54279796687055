import Dialog from '@mui/material/Dialog'
import React, { useEffect, useState } from 'react'
import { ButtonType, NeedleButton } from '../../../components/NeedleButton'
import { useNavigate } from 'react-router-dom'
import dialogStore, { DialogType } from '../../../storeZustand/dialogStore'
import Lottie from 'react-lottie'
import {
    creditIconAnimation,
    doneIconAnimation,
} from '../../../components/LottieAnimation'
import { Idea, IdeaStatus } from '../../../utils/needleEnum'
import useIdeaDetail, {
    changeIdeaStatus,
    reportConceptsAssets,
} from '../../../hooks/useIdeaDetail'
import ideasStore from '../../../storeZustand/ideasStore'
import { formatConceptsType } from './function'
import { PATH } from '../../../constants/path'
interface IdeasDetailDialogProps {
    userData: any
    id: string
}

export const IdeasDetailDialog = ({ userData, id }: IdeasDetailDialogProps) => {
    const navigate = useNavigate()

    const [header, setHeader] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [buttonKeyword, setButtonKeyWord] = useState<string>('')
    const [icon, setIcon] = useState<any>()
    const [isHaveCancel, setIsHaveCancel] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isHaveDescription, setIsHaveDescription] = useState<boolean>(false)
    const [userFeedback, setUserFeedback] = useState<string>('')
    const { reload } = useIdeaDetail(id)
    const { setShouldReload } = ideasStore((state) => ({
        setShouldReload: state.setShouldReload,
    }))
    const [newIdeaId, setNewIdeaId] = useState<string | undefined>()
    const workspace = userData.workspace
    const credit_balance =
        (workspace?.paid_credit ?? 0) +
        (workspace?.free_credit ?? 0) -
        (workspace?.used_credit ?? 0)

    const {
        isOpen,
        setIsOpen,
        creditCost,
        dialogType,
        conceptsType,
        conceptsId,
        quotaLeft,
        assetId
    } = dialogStore((state) => ({
        isOpen: state.isOpen,
        setIsOpen: state.setIsOpen,
        creditCost: state.creditCost,
        dialogType: state.dialogType,
        conceptsType: state.conceptsType,
        conceptsId: state.conceptsId,
        quotaLeft: state.quotaLeft,
        assetId: state.assetId,
    }))

    const { setOpenForNeedle } = dialogStore((state) => ({
        setOpenForNeedle: state.setOpenForNeedle,
    }))

    const isCreditReady = credit_balance >= creditCost

    const keyword = formatConceptsType(conceptsType)

    useEffect(() => {
        if (isOpen) {
            setIsHaveDescription(false)
            setIsHaveCancel(true)
            switch (dialogType) {
                case DialogType.SomeThingWrong:
                    setHeader('I’d like to request a change')
                    setDescription(`
                        <div>
                            <p>Tell me what went wrong, and my team will reach out soon to sort it out. </p>
                        </div>
                        `)
                    setButtonKeyWord('Submit')
                    setIcon('')
                    setIsHaveDescription(true)
                    break
                case DialogType.Archive:
                    setHeader('Archive idea?')
                    setDescription(
                        'You’ll still be able to find all archived ideas on the Ideas page.'
                    )
                    setButtonKeyWord('Archive')
                    setIcon('')
                    break
                case DialogType.RestoreIdeas:
                    setHeader('Restore idea?')
                    setDescription(
                        'This un-archives the idea and allows me to work on it.'
                    )
                    setButtonKeyWord('Restore')
                    setIcon('')
                    break
                case DialogType.MarkAsDone:
                    setHeader('Mark idea as Done?')
                    setDescription(
                        'This moves the idea to the “Done” column on the Actions page.'
                    )
                    setButtonKeyWord('Mark as Done')
                    setIcon('')
                    break
                case DialogType.QuotaWarning:
                    setHeader('You’ll need to spend Credits on this')
                    setDescription(`
                        <div>
                            <p>This exceeds your subscription quota for the current cycle. </p>
                            <p>You can still request for it, but you will need to spend Credits to do so. </p><br>
                        </div>
                    `)
                    setButtonKeyWord('Next Step')
                    setIcon(
                        <div className="justify-center items-center flex w-full relative pt-14 pb-10px">
                            <div className=" w-32 h-32 absolute -top-9">
                                <Lottie
                                    options={creditIconAnimation}
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                        </div>
                    )
                    break
                case DialogType.WithinQuota: 
                    setButtonKeyWord('Confirm')
                    setHeader(
                        `Do you want me to work on this?`
                    )
                    setDescription(`
                    <div>
                        <p>You have <b>${quotaLeft} ${conceptsType} left</b> in your subscription this month.</p><br>
                    </div>
                    `)
                    setButtonKeyWord('Confirm')
                    break
                case DialogType.NeedleHelp:
                    setIcon(
                        <div className="justify-center items-center flex w-full relative pt-14 pb-10px">
                            <div className=" w-32 h-32 absolute -top-9">
                                <Lottie
                                    options={creditIconAnimation}
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                        </div>
                    )
                    if (isCreditReady) {
                        setHeader(
                            `Spend ${creditCost} Credit${creditCost === 1 ? '' : 's'}?`
                        )
                        setDescription(`
                        <div>
                            <p>I’ll generate ${keyword} <strong>5 working days</strong> after you've uploaded the required assets.</p><br>
                            <p>The final output might be tweaked slightly, to make sure we end up with the best possible creative.</p>
                        </div>
                        `)
                        setButtonKeyWord('Confirm')
                    } else {
                        setHeader('You don’t have enough Credits')
                        setDescription(
                            `Add more Credits so that I can work on this.`
                        )
                        setButtonKeyWord('Add Credits')
                    }
                    break
            }
        }
    }, [isOpen])

    const handleCatchEvent = (): void => {
        switch (dialogType) {
            case DialogType.QuotaWarning:
                setIsLoading(true)
                setIsOpen(false)
                setTimeout(() => {
                    setIsLoading(false)

                    setOpenForNeedle(
                        true,
                        DialogType.NeedleHelp,
                        creditCost,
                        conceptsType,
                        conceptsId,
                    )
                }, 500)
                
                break
            case DialogType.WithinQuota:
                if (isHaveCancel === false) {
                    setIsOpen(false)
                    if (newIdeaId) {
                        window.location.href = PATH.ideasDetail.replace(
                            ':id',
                            newIdeaId
                        )
                    }
                    break
                }
                setIsLoading(true)
                    changeIdeaStatus(
                        id,
                        IdeaStatus.needle_is_working,
                        () => {},
                        conceptsId,
                        assetId
                    ).then((data) => {
                        setIsLoading(false)
                        if (data) {
                            setNewIdeaId(data.idea.id)
                            setHeader(`Working on it!`)
                            setDescription(`
                            <div>
                                <p>You’ll get notified once the asset(s) are ready.</p>
                            </div>
                            `)
                            setButtonKeyWord('Sounds good')
                            setIcon(
                                <div className="justify-center items-center flex w-full relative pt-8 pb-10px">
                                    <div className=" w-20 h-20 absolute -top-6">
                                        <Lottie
                                            options={doneIconAnimation}
                                            isClickToPauseDisabled={true}
                                        />
                                    </div>
                                </div>
                            )
                            setIsHaveCancel(false)
                        } else {
                            setHeader(`Something went wrong`)
                            setDescription(
                                `<div>We’re having trouble completing your request right now. Please try again. 
                                <br></br>
                                No Credits were deducted from your account.</div>`
                            )
                            setButtonKeyWord('Okay')
                            setIsHaveCancel(false)
                            setIcon('')
                        }
                    })
                break
            case DialogType.RestoreIdeas:
                changeIdeaStatus(id, IdeaStatus.read, reload)
                setShouldReload(true)
                setIsOpen(false)
                break
            case DialogType.Archive:
                changeIdeaStatus(id, IdeaStatus.archived, reload)
                setShouldReload(true)
                setIsOpen(false)
                break
            case DialogType.SomeThingWrong:
                if (isHaveCancel === false) {
                    setIsOpen(false)
                    break
                }
                setHeader(`Feedback submitted`)
                setDescription(`My team will reach out to you soon!`)
                setButtonKeyWord('Got it')
                setIsHaveCancel(false)
                setIsHaveDescription(false)
                setUserFeedback('')
                reportConceptsAssets(id, { message: userFeedback })
                break
            case DialogType.MarkAsDone:
                changeIdeaStatus(id, IdeaStatus.done, reload)
                setShouldReload(true)
                setIsOpen(false)
                break
            case DialogType.NeedleHelp:
                if (isCreditReady) {
                    if (isHaveCancel === false) {
                        setIsOpen(false)
                        if (newIdeaId) {
                            window.location.href = PATH.ideasDetail.replace(
                                ':id',
                                newIdeaId
                            )
                        }
                        break
                    }
                    setIsLoading(true)
                    changeIdeaStatus(
                        id,
                        IdeaStatus.needle_is_working,
                        () => {},
                        conceptsId
                    ).then((data) => {
                        setIsLoading(false)
                        if (data) {
                            setNewIdeaId(data.idea.id)
                            setHeader(`Credits deducted successfully`)
                            setDescription(`
                            <div>
                                <p>I’ll generate the asset in <strong>5 working days.</strong></p>
                            </div>
                            `)
                            setButtonKeyWord('Sounds good')
                            setIcon(
                                <div className="justify-center items-center flex w-full relative pt-8 pb-10px">
                                    <div className=" w-20 h-20 absolute -top-6">
                                        <Lottie
                                            options={doneIconAnimation}
                                            isClickToPauseDisabled={true}
                                        />
                                    </div>
                                </div>
                            )
                            setIsHaveCancel(false)
                        } else {
                            setHeader(`Something went wrong`)
                            setDescription(
                                `<div>We’re having trouble completing your request right now. Please try again. 
                                <br></br>
                                No Credits were deducted from your account.</div>`
                            )
                            setButtonKeyWord('Okay')
                            setIsHaveCancel(false)
                            setIcon('')
                        }
                    })
                } else {
                    navigate(PATH.creditPage)
                    setIsOpen(false)
                }
                break
            default:
                setIsOpen(false)
                break
        }
    }

    return (
        <Dialog open={isOpen}>
            <div className="relative w-480px">
                <div className="text-dark-blue p-6">
                    {icon}
                    <div className="font-header-2">{header}</div>
                    <div
                        className="font-body-text pt-10px"
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    ></div>
                    {isHaveDescription && (
                        <textarea
                            rows={3}
                            id="custom-review"
                            className="border border-beige-outline font-body-text 
                            py-3 px-4 w-full rounded-lg focus:outline-dark-blue mt-10px"
                            placeholder={'Describe what went wrong'}
                            value={userFeedback}
                            onChange={(e) => setUserFeedback(e.target.value)}
                        />
                    )}
                </div>
                <div className="bg-beige-outline h-1px w-full absolute left-0" />
                <div className="flex flex-row items-end py-4 pr-6 justify-end">
                    {isHaveCancel && (
                        <div>
                            <NeedleButton
                                buttonCharacter="Cancel"
                                onClickFunction={() => setIsOpen(false)}
                                buttonType={ButtonType.White}
                            />
                        </div>
                    )}
                    <div className="pl-2">
                        <NeedleButton
                            buttonCharacter={buttonKeyword}
                            onClickFunction={handleCatchEvent}
                            isDisable={isLoading}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default IdeasDetailDialog
