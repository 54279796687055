import { DeliveryServices } from '../services/Delivery/delivery.services'
import useSWR from 'swr'

export const useGetCurrentDeliveries = (workspaceId: string) => {
    const { data, mutate, ...rest } = useSWR(
        workspaceId ? `/get-current-delivery/${workspaceId}` : null,
        async () => {
            const getCurrentDelivery = await DeliveryServices.getCurrentDelivery(workspaceId)
            // return getCurrentDelivery
            return {
                asset: [],
                next_cycle: {
                    date: "0",
                    is_extended: false
                }
            }
        }
    )
    const refetch = () => {
        mutate()
    }
    return { data, mutate, refetch, ...rest }
}

export const useIsConceptWithinDelivery = (workspaceId: string, assetId: string) => {
    const { data, mutate, ...rest } = useSWR(
        workspaceId ? `/get-concept-within-delivery/${workspaceId}/${assetId}` : null,
        async () => {
            if (!workspaceId) return

            const getCurrentDelivery = await DeliveryServices.getCurrentDelivery(workspaceId)
            return {status:"NON_SUBSCRIBER", available: 0}

            if (getCurrentDelivery.asset.length === 0) {
                return {status:"NON_SUBSCRIBER", available: 0}
            }
            const isConceptWithinDelivery = getCurrentDelivery.asset.find((asset: any) => asset.id === assetId && asset.picked < asset.quantity)
            
            if (isConceptWithinDelivery) {
                return {status:"YES", available: isConceptWithinDelivery.quantity - isConceptWithinDelivery.picked}
            } else {
                return {status:"NO", available: 0}
            }
        }
    )
    const refetch = () => {
        mutate()
    }
    return { data, mutate, refetch, ...rest }
}

